<template>
  <div :class="{ 'hide': src === null }">
    <video 
      ref="videoPlayer" 
      class="video-js" 
      :tech-order="techOrder" 
      :autoplay="true" 
      :controls="true" 
      :preload="true"
      @changed="playerStateChanged($event)">
    </video>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import 'videojs-youtube';
import notify from "@/services/libs/notificacao";

export default {
  name: "VideoPlayer",
  props: ['porcentagem', "source", "thumb"],
  data() {
    return {
      autoplay: true,
      player: null,
      techOrder: "youtube",
      type: "video/youtube",
      src: "",
    }
  },
  computed: {},
  methods: {
    playerDispose() {
      this.videoPlayer.dispose();
    },
    playerGetTime() {
      let duration = this.videoPlayer.currentTime()
      let minutes = Math.floor(duration / 60);
      let seconds = duration - (minutes * 60)
      let time = `${minutes} minutos e ${seconds}`;

      //console.log('yt - tempo atual convertido', time);
      //console.log('yt - tempo atual em segundos', duration);

      let duration2 = this.videoPlayer.duration();
      let minutes2 = Math.floor(duration2 / 60);
      let seconds2 = duration2 - (minutes2 * 60)
      let time2 = `${minutes2} minutos e ${seconds2}`;

      //console.log('yt - duração total convertida', time2);
      //console.log('yt - duração total em segundos', duration2);
    },
    getPlayer() {
      this.videoPlayer = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
        //console.log('onPlayerReady', this);
      });
    },
    play() {
      this.videoPlayer.play();
    },
    pause() {
      this.videoPlayer.pause();
      //console.log('video is paused')
    },
    onPlayerPause: function (playerCurrentState) {
      //console.log('pause', playerCurrentState);
    },
    playerStateChanged: function (playerCurrentState) {
      //console.log('player current update state', playerCurrentState);
    },
    youtubeParser(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return (match && match[7].length == 11) ? match[7] : false;
    },
    loadVideo() {
      var player = videojs(this.$refs.videoPlayer);
      if (this.source === null) {
        this.src = null;
        player.src([
          { type: "video/youtube", src: null },
        ]);
        player.poster(null);
        this.videoPlayer.pause();
        this.getPlayer();
        return false
      }
      this.getPlayer();
      let id = this.youtubeParser(this.source);
      this.src = 'https://www.youtube.com/watch?v=' + id;
      this.type = "video/youtube",
        player.src([
          { type: this.type, src: this.src },
        ]);
      player.poster(this.thumb);
    },
    async calculaPorcentagem(type) {
      var current = await this.videoPlayer.currentTime();
      var total = await this.videoPlayer.duration();
      current = parseInt(current, 10);
      total = parseInt(total, 10);
      //console.log('função q calcula', current, total, 'porcentagem necessaria:', parseInt(this.porcentagem, 10));
      var calc = (current / total) * 100;
      calc = parseInt(calc, 10);
      //console.log('resultado calculo:', calc);
      if (calc >= parseInt(this.porcentagem, 10)) {
        //console.log('emite evento para liberar os btn', type);
        if (type === 'concluir') {
          this.$root.$emit('liberaBtn');
        } else if (type === 'calcula') {
          this.$root.$emit('calculaBtn');
        } else {
          this.$root.$emit('liberaBtnNext');
        }
      } else {
        //console.log('caiu no else ??????')
        notify('erro', this.$t('player.errors_players.msg3'));
        //console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx youtube')
      }
    },
  },
  mounted() {
    this.getPlayer();
    this.loadVideo();
    this.$root.$on('clicouConcluir', data => {
      if (data === 'youtube') {
        //console.log('chama função q calcula');
        this.calculaPorcentagem('concluir');
      }
    });
    this.$root.$on('clicouNext', data => {
      if (data === 'youtube') {
        this.calculaPorcentagem('next');
      }
    });
    this.$root.$on('calcula', data => {
      if (data === 'youtube') {
        this.calculaPorcentagem('calcula');
      }
    });
    this.$root.$on('dataLesson', data => {
      //console.log("data lesson", data);
      var myPlayer = videojs(this.$refs.videoPlayer);
      if (data === null) {
        this.src = null;
        myPlayer.src([
          { type: "video/youtube", src: null },
        ]);
        myPlayer.poster(null);
        this.videoPlayer.pause();
        this.getPlayer();
        return false
      }
      this.getPlayer();
      this.techOrder = data.mediaType;
      if (this.techOrder === 'youtube') {
        let id = this.youtubeParser(data.source);
        this.src = 'https://www.youtube.com/watch?v=' + id;
        this.type = "video/youtube",
          myPlayer.src([
            { type: this.type, src: this.src },
          ]);
        myPlayer.poster(data.thumb);
      }
    });
  },
  watch: {
    source() {
      this.loadVideo();
    }
  },
  beforeDestroy() {
    this.videoPlayer.dispose();
  }
}
</script>
<style lang="scss">
.video-js {
  width: 100% !important;
  // height: 100% !important;
  height: 47vh !important;
  z-index: 99;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}

.toZindex .video-js {
  z-index: 99999999999999999;
}

.hide {
  display: none !important;
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.video-js .vjs-big-play-button {
  left: 45% !important;
  top: 48% !important;
}
</style>